<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <!-- <el-form-item>
          <el-input v-model="search.uid" placeholder="用户标识"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-input v-model.trim="search.uuid" @change="Search" placeholder="设备标识"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <!-- <el-button type="primary" size="small" @click="addUser">新建</el-button> -->
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="设备编号">
        <template v-slot="scope">
          <span>{{ scope.row.device_id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备唯一标识">
        <template v-slot="scope">
          <span>{{ scope.row.uuid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否绑定">
        <template v-slot="scope">
          <span style="color:green" v-if="scope.row.status==1">{{ '已绑定' }}</span>
          <span style="color:red" v-else-if="scope.row.status==2">{{ '已解绑' }}</span>
          <span v-else>{{ '未知' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="绑定关系">
        <template v-slot="scope">
          <span>{{ scope.row.bind_type==1?'主人':scope.row.bind_type==2?'分享':'未知' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品型号">
        <template v-slot="scope">
          <span>{{ scope.row.model }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备关系排序">
        <template v-slot="scope">
          <span>{{ scope.row.sort }}</span>
        </template>
      </el-table-column>
      <el-table-column label="休眠/p2p在线状态">
        <template v-slot="scope">
          <span style="color:green" v-if="scope.row.open_status==1">在线/</span>
          <span style="color:red" v-else>不在线/</span>
          <span style="color:green" v-if="scope.row.p2p_online==1">在线</span>
          <span style="color:red" v-else>不在线</span>
        </template>
      </el-table-column>
      <el-table-column label="绑定时间">
        <template v-slot="scope">
          <span>{{ changeTime(scope.row.bind_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="解绑时间">
        <template v-slot="scope">
          <span>{{ changeTime(scope.row.delete_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="第一次绑定时间">
        <template v-slot="scope">
          <span>{{ changeTime(scope.row.first_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="350px">
        <template v-slot="scope">
          <router-link :to="'/iot/onlineinfo/'+scope.row.uuid + '@' + params" style="margin-right:10px">
            <el-button type="info" size="mini">详情</el-button>
          </router-link>
          <el-button type="danger" v-if="scope.row.status==1" size="mini" @click="unbinding(scope.row)">取消绑定</el-button>
          <el-button v-if="scope.row.status==1" type="info" size="mini" @click="CreateCloud(scope.row)">开通套餐</el-button>
          <el-button type="info" size="mini" @click="GetCloudInfo(scope.row)">云存储</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination> -->

    <el-dialog title="开通云存储情况" v-model="cloudFormVisible" width="1200px" center>
      <el-table border v-bind:data="cloudList">
        <el-table-column label="订单id">
          <template v-slot="scope">
            <span>{{ scope.row.order_id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备uuid">
          <template v-slot="scope">
            <span>{{ scope.row.uuid }}</span>
          </template>
        </el-table-column>
        <el-table-column label="套餐开始时间">
          <template v-slot="scope">
            <span>{{ changeTime(scope.row.start_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="套餐结束时间">
          <template v-slot="scope">
            <span>{{ changeTime(scope.row.end_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="套餐内云存储时间(天)">
          <template v-slot="scope">
            <span>{{ scope.row.file_time }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template v-slot="scope">
            <span>{{ scope.row.status==0?'未订阅':scope.row.status==1?'已订阅':'未知' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="云存储事件类型">
          <template v-slot="scope">
            <span>{{ scope.row.is_event==0?'全时':scope.row.is_event==1?'事件':'未知' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订阅的app">
          <template v-slot="scope">
            <span>{{ scope.row.schema }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button v-if="scope.row.file_time!==0" type="text" @click="S3KeyAnalyse(scope.row)">S3key分析</el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cloudFormVisible=false">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- s3key分析 -->
    <el-dialog title="S3key分析" v-model="s3FormVisible" width="1000px" center>
      <el-form :model="s3form" :inline="true" class="demo-form-inline" :rules="s3formrules" ref="logform">
        <el-form-item label="日期" prop="date" label-width="60px">
          <el-date-picker
            v-model="s3form.date"
            type="date"
            placeholder="Pick a Date"
            @change="GetS3key"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-form v-if="formVisibl" :model="form" :inline="true" class="demo-form-inline">
      <!-- <el-form-item label="appid分析:" label-width="100px">
        <el-input disabled style="width:200px" v-model="form.appid_equal" placeholder=""></el-input>
      </el-form-item> -->
      <el-form-item label="文件位置:" label-width="100px">
        <el-input disabled style="width:200px" v-model="form.file_brand" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="版本:" label-width="100px">
        <el-input disabled style="width:200px" v-model="form.version" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="区域:" label-width="100px">
        <el-input disabled style="width:200px" v-model="form.region" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="文件目录:" label-width="100px">
        <el-input disabled style="width:620px" v-model="form.file_key" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="品牌分析:" label-width="100px">
        <el-input disabled style="width:620px" v-model="form.brand_equal" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="目录分析:" label-width="100px">
        <el-input disabled style="width:620px" v-model="form.key_appid_right" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="位置分析:" label-width="100px">
        <el-input disabled style="width:620px" v-model="form.key_brand_right" placeholder=""></el-input>
      </el-form-item>
    </el-form>
      <el-table border v-bind:data="s3KeyList">
        <el-table-column label="key">
          <template v-slot="scope">
            <span>{{ scope.row.key }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="200px">
          <template v-slot="scope">
            <el-button size="mini" type="info" @click="Down(scope.row)">下载 </el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- <template #footer>
        <span class="dialog-footer">
          <el-button @click="s3FormVisible=false">取消</el-button>
        </span>
      </template> -->
    </el-dialog>

    <el-dialog title="开通套餐" v-model="packFormVisible" width="30%" center>
      <el-form label-width="80px" ref="creatcloudform" :model="creatcloudform" :rules="rules">
        <el-form-item label="套餐" prop="product_id">
          <el-select v-model="creatcloudform.product_id">
            <el-option
              v-for="item in packList"
              :key="item.SetMeal.code"
              :label="item.SetMeal.name"
              v-show="item.SetMeal.status!==3"
              :value="item.SetMeal.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐时长" prop="cycle">
          <el-input-number
            v-model="creatcloudform.cycle"
            :min="0"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="packFormVisible=false">取消</el-button>
          <el-button @click="Validate(CreateConfirm)">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../../axios/pack'
import util from '../../../util/util'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        uid: '',
        uuid: '',
        region: '',
      },
      list: [],
      total: 0,
      schema: '',
      params: '',
      packFormVisible: false,
      creatcloudform: {
        product_id: '',
        uid: '',
        uuid: '',
        region: '',
        cycle: ''
      },
      rules: {
        product_id: [{ required: true, message: '请选择套餐', trigger: 'blur' }],
        cycle: [{ required: true, message: '请输入套餐时长', trigger: 'blur' }],
      },
      packList: [],
      cloudFormVisible: false,
      cloudList: [{}],
      currencyList: [],
      // s3key分析
      formVisibl: false,
      form: {
        appid_equal: '',
        file_brand: '',
        brand_equal: '',
        version: '',
        region: '',
        file_key: '',
        key_appid_right: '',
        key_brand_right: '',
      },
      s3FormVisible: false,
      s3form: {
        date: '',
        appid: '271050dab986186e',
        uid: '',
        uuid: '',
        file_time: ''
      },
      s3formrules: {},
      s3KeyList: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    S3KeyAnalyse (item) {
      this.s3form.uuid = item.uuid
      this.s3form.file_time = item.file_time
      this.s3form.appid = item.schema
      this.GetS3key()
    },
    GetS3key () {
      this.s3KeyList = []
      this.form = {}
      let time = this.s3form.date?util.dateSingle(this.s3form.date):''
      this.api.FileLogListAnalyse({
        rows: 10,
        prefix: 'media'+this.s3form.file_time+'/'+this.s3form.appid+'/'+this.s3form.uuid+'_'+this.s3form.uid+'/'+ time,
        region: this.search.region
      }).then(res => {
        if (res.data.code == 200) {
          this.s3FormVisible = true
          this.formVisibl = true
          this.form.appid_equal = res.data.data.appid_equal
          this.form.file_brand = res.data.data.file_brand
          this.form.brand_equal = res.data.data.brand_equal
          this.form.version = res.data.data.version
          this.form.region = res.data.data.region
          this.form.file_key = res.data.data.file_key
          this.form.key_appid_right = res.data.data.key_appid_right
          this.form.key_brand_right = res.data.data.key_brand_right
          this.s3KeyList = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Down (item) {
      this.api.FileLogDown({
        brand: this.form.file_brand,
        key: item.key,
        region: this.search.region
      }).then(res => {
        if (res.data.code == 200) {
          let imgurl = res.data.data.get_url
          let link = document.createElement('a')
          link.setAttribute('href', imgurl)
          link.setAttribute('target', '_blank')
          link.click()
          return
        }
        this.$message.error('下载失败' + res.data.msg)
      })
    },
    GetCurrency () {
      this.api.CurrencyList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.currencyList = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    changeTime (item) {
      return util.dateFormat(item)
    },
    GetCloudInfo (item) {
      this.s3form.uid = item.uid
      this.api.OnlineCloudInfo({
        uid: item.uid,
        uuid: item.uuid,
        region: this.search.region
      }).then(res => {
        if (res.data.code == 200) {
          this.cloudList = res.data.data
          this.cloudFormVisible = true
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    CreateCloud (item) {
      this.packFormVisible = true
      this.creatcloudform.uid = item.uid
      this.creatcloudform.uuid = item.uuid
      this.creatcloudform.region = this.search.region
      api.PackageList({
        page: 1,
        limit: 100,
        kname: '',
        code: '',
        is_event: -1,
        // product_model_code: this.form.device.model_code,
      }).then(res => {
        if (res.data.code == 200) {
          this.packList = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['creatcloudform'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    CreateConfirm () {
      this.api.OnlineCreateCloud(this.creatcloudform,{region:this.search.region}).then(res => {
        if (res.data.code == 200) {
          this.$message.success('套餐开通成功!')
          this.packFormVisible = false
          return
        }
        this.$message.error('套餐开通失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search () {
      this.api.UserDeviceList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data
          // this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    binding (item) {
      this.api.UserDeviceUpdate({
        code: item.code,
        device_id: item.device_id,
        status: item.status,
        uid: item.uid,
        uuid: item.uuid,
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('绑定成功')
          return
        }
        this.$message.error('绑定失败' + res.data.msg)
      })
    },
    unbinding (item) {
      this.api.DeleteBind({
        schema: this.schema,
        uid: item.uid,
        uuid: item.uuid,
        region: this.search.region
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('解绑成功')
          return
        }
        this.$message.error('解绑失败' + res.data.msg)
      })
    },
    goback (){
      this.$router.push('/iot/user')
    }
  },
  filters: {},
  mounted () {
    this.params = this.$route.params.uid
    this.search.uid = this.$route.params.uid.split('&')[0]
    this.schema = this.$route.params.uid.split('&')[1]
    this.search.region = this.$route.params.uid.split('&')[2]
    this.Search()
  },
  created () {
    // this.GetCurrency()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
